<template>
  <v-card>
    <v-card-title class="accent">
      <v-icon class="mr-2">mdi-currency-usd</v-icon>
      Vendas
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            text
            @click="getUsers"
            class="mr-2"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </template>
        <span>Recarregar</span>
      </v-tooltip>
      <h3 class="text-subtitle-1">
        Total
        <span class="font-weight-medium">{{ totalUsuarios }}</span>
      </h3>
    </v-card-title>

    <v-divider></v-divider>
    <v-card-text class="d-flex align-center justify-center" v-if="graficoData.length">
      <Doughnut
        :chartData="graficoUsuarios"
        :options="options"
        :styles="styling"
      />
    </v-card-text>
    <v-card-text
      v-else
      class="py-6 text-subtitle-1 d-flex align-center justify-center"
    >
      Nenhum Usuário Registrado
    </v-card-text>
  </v-card>
</template>

<script>
import api from "@/api/axios_service";

export default {
  name: "BoxVendasTipos",

  components: {
    Doughnut: () => import("../graficos/Doughnut"),
  },

  data() {
    return {
      graficoData: [],
      graficoLabel: [],
      graficoColor: [],
      loading: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      styling: {
        width: "300px",
      },
    };
  },

  computed: {
    graficoUsuarios() {
      return {
        labels: this.graficoLabel,
        datasets: [
          {
            data: this.graficoData,
            backgroundColor: this.graficoColor,
          },
        ],
      };
    },
    totalUsuarios() {
      let result = 0;
      if (this.graficoData.length) {
        result = this.graficoData.reduce(
          (accumulator, dado) => accumulator + dado,
          0
        );
      }
      return result;
    },
  },

  methods: {
    async getUsers() {
      this.loading = true;

      this.graficoData = [];
      this.graficoLabel = [];
      this.graficoColor = [];

      const { data } = await api.get("/graficos/box-user-vendas");

      const colors = {
        vendaseduz: "#efbd3c",
        vendamanual: "#dddddd",
        vendavips: "#111111",
      };

      if (data.result.vendaseduz) {
        this.graficoData.push(data.result.vendaseduz);
        this.graficoLabel.push("Eduzz");
        this.graficoColor.push(colors.vendaseduz);
      }

      if (data.result.vendamanual) {
        this.graficoData.push(data.result.vendamanual);
        this.graficoLabel.push("Manual");
        this.graficoColor.push(colors.vendamanual);
      }

      if (data.result.vendavips) {
        this.graficoData.push(data.result.vendavips);
        this.graficoLabel.push("Vips");
        this.graficoColor.push(colors.vendavips);
      }

      this.loading = false;
    },
  },

  mounted() {
    this.getUsers();
  },
};
</script>

<style></style>
